<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="100vw" style="background:lightgray">

        <v-card class="pa-2 ma-2" min-height="170px" max-height="170px" outlined tile style="font-size: 12px;">
          <v-row>
            <v-col cols="12" sm="3">
              <div><span class="line-header">Sales Order:</span> {{sales_order}}</div>
              <div><span class="line-header">Customer:</span> {{db.sales_orders[sales_order].customer_name}}</div>
              <div><span class="line-header">Customer PO:</span> {{db.sales_orders[sales_order].customer_po}}</div>

            </v-col>
            <v-col cols="12" sm="3">
              <div><span class="line-header">Ordered By:</span> {{db.sales_orders[sales_order].ordered_by_name}} <span v-if="db.sales_orders[sales_order].email_address_of_ordered_by" style="font-size: 12px">({{db.sales_orders[sales_order].email_address_of_ordered_by}})</span></div>
              <div><span class="line-header">Order Writer:</span> {{db.sales_orders[sales_order].order_writer_name}}</div>
              <div><span class="line-header">Complete Order Status:</span> {{db.sales_orders[sales_order].complete_order_status}}</div>
            </v-col>
            <v-col cols="12" sm="3">
              <div><span class="line-header">Ship Via Code:</span> {{db.sales_orders[sales_order].ship_via_code}}</div>
              <div><span class="line-header">Ship Date:</span> {{db.sales_orders[sales_order].ship_date}}</div>
              <div><span class="line-header">Ship To City:</span> {{db.sales_orders[sales_order].ship_to_city}}</div>
            </v-col>
            <v-col cols="12" sm="3">
              <div><span class="line-header">Shipping Company:</span> {{db.sales_orders[sales_order].shipping_company}}</div>
              <div><span class="line-header">Tracking Company:</span> {{db.sales_orders[sales_order].tracking_number}}</div>
            </v-col>
          </v-row>
          <hr style="margin-top:5px;margin-bottom:5px;color: gray;">
          <v-row>
            <v-col cols="12" sm="2" v-if="db.sales_orders[sales_order].shipping_company && db.sales_orders[sales_order].shipping_company.trim().length > 0) || (db.sales_orders[sales_order].tracking_number && db.sales_orders[sales_order].tracking_number.trim().length > 0">
              <v-select
                v-model="shipping_status"
                :items="shippingStatusOptions"
                item-value="value"
                item-text="text"
                outlined
                density="compact"
                @update:modelValue="onShippingStatusChange"
                label="Shipping Status"
              ></v-select>
            </v-col>            
            <v-col cols="12" sm="4">
              <v-btn size="x-small" style="background:#ff7214;color:white;margin-bottom:5px;margin-top:2px" @click="updateSalesOrderEta()">{{db.sales_orders[sales_order].eta ? 'Update' : 'Set'}} ETA</v-btn>
              <div style="font-size: 12px;" v-if="db.sales_orders[sales_order].eta">ETA: {{db.sales_orders[sales_order].eta}} <span style="font-size:12px;font-style:italic">updated {{formatDate(db.sales_orders[sales_order].eta_last_updated)}} by {{db.users[db.sales_orders[sales_order].eta_last_updated_by].first_name}} {{db.users[db.sales_orders[sales_order].eta_last_updated_by].last_name}}</span></div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn size="x-small" style="background:#ff7214;color:white" @click="addNote()">Add Internal Note</v-btn>
              <div>
                <div v-for="n in sortedInternalNotes" class="note-container">
                  <div class="note">{{n.note}} <span class="note-detail">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}}</span></div>
                  
                </div>                     
              </div>
            </v-col>
          </v-row>          
        </v-card>

<!--         <v-card class="pa-2 ma-2" min-height="120px" max-height="120px" style="overflow-y: auto" outlined tile>    
          <v-row>
            <v-col cols="12" sm="2" v-if="db.sales_orders[sales_order].shipping_company && db.sales_orders[sales_order].shipping_company.trim().length > 0) || (db.sales_orders[sales_order].tracking_number && db.sales_orders[sales_order].tracking_number.trim().length > 0">
              <v-select
                v-model="shipping_status"
                :items="shippingStatusOptions"
                item-value="value"
                item-text="text"
                outlined
                density="compact"
                @update:modelValue="onShippingStatusChange"
                label="Shipping Status"
              ></v-select>
            </v-col>            
            <v-col cols="12" sm="4">
              <v-btn size="x-small" style="background:#ff7214;color:white;margin-bottom:5px;margin-top:2px" @click="updateSalesOrderEta()">{{db.sales_orders[sales_order].eta ? 'Update' : 'Set'}} ETA</v-btn>
              <div style="font-size: 12px;" v-if="db.sales_orders[sales_order].eta">ETA: {{db.sales_orders[sales_order].eta}} <span style="font-size:12px;font-style:italic">updated {{formatDate(db.sales_orders[sales_order].eta_last_updated)}} by {{db.users[db.sales_orders[sales_order].eta_last_updated_by].first_name}} {{db.users[db.sales_orders[sales_order].eta_last_updated_by].last_name}}</span></div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn size="x-small" style="background:#ff7214;color:white" @click="addNote()">Add Internal Note</v-btn>
              <div>
                <div v-for="n in sortedInternalNotes" class="note-container">
                  <div class="note">{{n.note}} <span class="note-detail">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}}</span></div>
                  
                </div>                     
              </div>
            </v-col>
          </v-row>
        </v-card> -->

        <v-card class="pa-2 ma-2" outlined tile>
          <Vue3EasyDataTable
            :headers="items_headers"
            :items="sales_order_info.items"
            :table-height="table_height"
            :rows-per-page="50000"
            :hide-rows-per-page="true"
            :hide-footer="false"
            sort-by="quantity_backordered"
            sort-type="desc"
          >

            <template #item-quantity_ordered="item">
              <div style="text-align: center;width:100px;">{{item.quantity_ordered}}</div>
            </template>

            <template #item-quantity_backordered="item">
              <div style="background:yellow;border:1px solid;border-radius: 4px; width:100px;text-align: center" v-if="item.quantity_backordered > 0">{{item.quantity_backordered}}</div>
              <div style="text-align: center;width:100px;" v-if="item.quantity_backordered <= 0">{{item.quantity_backordered}}</div>
            </template>

            <template #item-module_po_number="item">
                <span v-if="!item.po_not_open_in_nest" style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openPurchaseOrderDetailPopup(item.module_po_number)">{{item.module_po_number}}</span>
                <div v-if="item.po_not_open_in_nest">{{item.module_po_number}}</div>
                <div v-if="item.unlinked_po_number && item.quantity_backordered > 0" style="background: pink;text-align: center;font-size:8px; font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">UNLINKED PO</div>
            </template>

            <template #item-eta_sortable="item">
              <span v-if="item.quantity_backordered === '0'">Received By RH</span>
              <span v-if="item.quantity_backordered !== '0'">{{item.eta_formatted}}</span>
              
            </template>

            <template #item-eta_last_provided_sortable="item">
              {{item.eta_last_provided_formatted}}
            </template>


          </Vue3EasyDataTable>
        </v-card>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:#ff7214;color:white" @click="$emit('close')">CLOSE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
  <UpdateSalesOrderEtaPopUp v-if="sales_order_eta_popup" @close="closeUpdateSalesOrderEtaPopUp()" :salesorder="sales_order"/>
  <NoteSalesOrderPopUp v-if="note_popup" @close="note_popup = false" :salesorder="sales_order"/>
  <PurchaseOrderDetailPopUp v-if="po_for_component" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';
import UpdateSalesOrderEtaPopUp from '@/components/UpdateSalesOrderEtaPopUp.vue'
import NoteSalesOrderPopUp from '@/components/NoteSalesOrderPopUp.vue'
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'

export default {
  name: 'SalesOrderDetailPopUp',
  components: {Vue3EasyDataTable,UpdateSalesOrderEtaPopUp,NoteSalesOrderPopUp,PurchaseOrderDetailPopUp},
  mixins: [],
  emits: ["close"],
  props:{
    sales_order: String
  },
  data(){
    return{
      dialog: true,
      note_popup: false,
      table_height: 100,
      sales_order_info: null,
      sales_order_eta_popup: false,
      shipping_status: null,
      po_for_component: false,
      items_headers: [
        { text: "Line Number", value: "line_number", sortable: true },
        { text: "Product Number", value: "product_number", sortable: true },
        { text: "Description Line 1", value: "description_line_one", sortable: true },
        { text: "Quantity Ordered", value: "quantity_ordered", sortable: true },
        { text: "Quantity Backordered", value: "quantity_backordered", sortable: true },
        { text: "Drop Ship", value: "drop_ship", sortable: true },
        { text: "Riverhawk PO #", value: "module_po_number", sortable: true },
        { text: "Tracking Info", value: "tracking_info", sortable: true },
        { text: "PO Confirmation Status", value: "po_confirmation_status", sortable: true },
        { text: "ETA", value: "eta_sortable", sortable: true },
        { text: "ETA Last Updated", value: "eta_last_provided_sortable", sortable: true },
        { text: "Vendor", value: "vendor", sortable: true },
      ],        
    }
  },
  watch: {
    'db.sales_orders': {
      handler() {
        if(this.db.sales_orders[this.sales_order].custom_shipping_status){
          this.shipping_status = this.db.sales_orders[this.sales_order].custom_shipping_status
        }else{
          this.shipping_status = this.db.sales_orders[this.sales_order].ship_date ? 'In Transit' : 'Not Yet Shipped'  
        }
      },
      deep: true, // Ensures Vue watches nested changes
      immediate: true
    },
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },  
  beforeMount(){
    var self = this
    self.sales_order_info = JSON.parse(JSON.stringify( self.db.sales_orders[self.sales_order] ))
    self.sales_order_info.items = []
    firebase.database().ref('backorders').orderByChild('order_number').equalTo(self.sales_order).once('value', function(snapshot) {
      var items = snapshot.val();
      for(var i in items){
        var item = items[i]
        item.drop_ship = item.line_item_warehouse === '09' ? 'YES' : 'NO'

        item.line_number = parseFloat(item.line_number, 10)

        item.quantity_ordered = parseFloat(item.quantity_ordered)
        item.quantity_backordered = parseFloat(item.quantity_backordered)

        // (1) linked PO found in NEST and item is found in Nest PO
        // (2) linked PO found in NEST but item NOT found in the Nest PO
        // (3) linked PO but the PO is NOT found/open in Nest
        // (4) no linked PO although was found item in open Nest PO(s) (find the OLDEST DATE po date one and use that info.)
        // (5) no linked PO and could not find item open Nest PO(s)

          if( self.db.purchase_orders[item.linked_po_number] ){

            for(var item_key in self.db.purchase_orders[item.linked_po_number].items){
              if(self.db.purchase_orders[item.linked_po_number].items[item_key].product === item.product_number){
                //(1)
                item.eta = self.db.purchase_orders[item.linked_po_number].items[item_key].eta
                item.eta_last_provided = self.db.purchase_orders[item.linked_po_number].items[item_key].eta_last_provided
                item.vendor = self.db.purchase_orders[item.linked_po_number].vendor_name
                item.tracking_info = self.db.purchase_orders[item.linked_po_number].items[item_key].tracking_number + ' ' + (self.db.purchase_orders[item.linked_po_number].items[item_key].shipping_company ? '(' + self.db.purchase_orders[item.linked_po_number].items[item_key].shipping_company + ')' : '')             
              }
            }
            //(2)
            if(item.eta === 'unknown'){
              item.eta = 'Linked PO found but no item match'
              item.tracking_info = ''
            }
            item.po_confirmation_status = self.po_confirmation_status(self.db.purchase_orders[item.linked_po_number])
          }else{

            if(item.linked_po_number){
              //(3)
              item.eta = 'Linked PO not open in Nest'
              item.po_not_open_in_nest = true
              item.po_confirmation_status = 'Linked PO not open in Nest'
              item.tracking_info = ''
            }

            if(!item.linked_po_number){
              var potential_purchase_orders = {}

              for(var po in self.db.purchase_orders){
                for(var item_key in self.db.purchase_orders[po].items){
                  if(self.db.purchase_orders[po].items[item_key].product === item.product_number){
                    potential_purchase_orders[po] = {
                      po_date: self.db.purchase_orders[po].po_date,
                      po_date_timestamp: Moment(self.db.purchase_orders[po].po_date).startOf('day').valueOf(),
                      eta: self.db.purchase_orders[po].items[item_key].eta,
                      eta_last_provided: self.db.purchase_orders[po].items[item_key].eta_last_provided,
                      vendor: self.db.purchase_orders[po].vendor_name,
                      po_confirmation_status: self.po_confirmation_status(self.db.purchase_orders[po]),
                      tracking_info: self.db.purchase_orders[po].items[item_key].tracking_number + ' ' + (self.db.purchase_orders[po].items[item_key].shipping_company ? '(' + self.db.purchase_orders[po].items[item_key].shipping_company + ')' : '')
                    }
                  }
                }
              }

              if(Object.keys(potential_purchase_orders).length > 0){
                // (4)
                const oldestPO = Object.keys(potential_purchase_orders).reduce((oldest, key) => {
                    return potential_purchase_orders[key].po_date_timestamp < potential_purchase_orders[oldest].po_date_timestamp ? key : oldest;
                    }, Object.keys(potential_purchase_orders)[0]);
                item.unlinked_po_number = oldestPO
                item.eta = potential_purchase_orders[oldestPO].eta
                item.eta_last_provided = potential_purchase_orders[oldestPO].eta_last_provided
                item.vendor = potential_purchase_orders[oldestPO].vendor
                item.po_confirmation_status = potential_purchase_orders[oldestPO].po_confirmation_status
                item.tracking_info = potential_purchase_orders[oldestPO].tracking_info
              }else{
                // (5)
                item.unlinked_po_number = 'No PO found'
                item.eta = 'No PO found'
                item.missing_po = true
                item.po_confirmation_status = 'No PO found'
                item.tracking_info = ''
              }              
            }
          }

          item.module_po_number = item.linked_po_number ? item.linked_po_number : item.unlinked_po_number
          item.module_po_number = item.quantity_backordered > 0 ? item.linked_po_number : ''
          item.tracking_info = item.quantity_backordered > 0 ? item.tracking_info : ''
          item.vendor = item.quantity_backordered > 0 ? item.vendor : ''
          item.po_confirmation_status = item.quantity_backordered > 0 ? item.po_confirmation_status : ''

          // if(item.quantity_backordered < 1 && item.eta == 'No PO found'){
          //   item.eta = ''
          // }

          if(item.quantity_backordered < 1){
            item.eta = ''
          }

          item.order_date_sortable = Moment(item.order_date).startOf('day').valueOf()
          item.order_date_formatted = Moment(item.order_date).format('MM/DD/YY');
          item.eta_is_date = self.isDateString(item.eta)
          item.is_white_glove = item.white_glove_support_order || item.white_glove_tracking_for_this_ship_to

          var eta_string_sorting_options = ['','Linked PO found but no item match','Linked PO not open in Nest','No PO found','TBD','UTP']

          if(item.eta_is_date){
            item.eta_sortable = Moment(item.eta).startOf('day').valueOf()
            item.eta_formatted = item.eta
            item.past_due = Moment(item.eta).startOf('day').valueOf() < Moment().startOf('day').valueOf()
          }else{
            item.eta_sortable = eta_string_sorting_options.indexOf(item.eta)
            item.eta_formatted = item.eta
          }
          
          if(item.eta_last_provided && item.quantity_backordered > 0 ){
            item.eta_last_provided_sortable = item.eta_last_provided
            item.eta_last_provided_formatted = Moment(item.eta_last_provided).format('MM/DD/YY')
          }else{
            item.eta_last_provided_sortable = 0
            item.eta_last_provided_formatted = ''
          }

          self.sales_order_info.items.push(items[i])
      }
    });
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
    sortedInternalNotes() {
      return _.orderBy(
        this.db.sales_orders[this.sales_order].internal_notes, // Collection to sort
        ['submitted_date'], // Key to sort by
        ['desc'] // Sort order: 'desc' for most recent first
      );
    },
    shippingStatusOptions(){
      return [
        { title: "Not Yet Shipped", value: "Not Yet Shipped", props: { disabled: this.db.sales_orders[this.sales_order].ship_date ? true : false } },
        { title: "In Transit", value: "In Transit", props: { disabled: this.db.sales_orders[this.sales_order].ship_date ? false : true  } },
        { title: "Issue", value: "Issue", props: { disabled: false } },
        { title: "Delivered", value: "Delivered", props: { disabled: false } },
        { title: "Complete", value: "Complete", props: { disabled: false } },
      ];
    }    
  },
  methods:{
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = false
    },    
    onShippingStatusChange(val){
      var self = this
      var timestamp = Date.now()
      
      if(val !== 'Not Yet Shipped' && val !== 'In Transit'){
        firebase.database().ref('sales_orders').child(self.sales_order).child('custom_shipping_status').set(val)
      }else{
        firebase.database().ref('sales_orders').child(self.sales_order).child('custom_shipping_status').remove()
      }
      
      if(val === 'Complete'){
        firebase.database().ref('sales_orders').child(self.sales_order).child('shipping_status_complete_timestamp').set(timestamp)
      }else{
        firebase.database().ref('sales_orders').child(self.sales_order).child('shipping_status_complete_timestamp').remove()
      }

      firebase.database().ref('sales_orders').child(self.sales_order).child('shipping_status_log').push({status: val, user: self.user, timestamp: timestamp})
    },
    addNote(type){
      this.note_popup = true
    },    
    isDateString(str){
      if (typeof str !== 'string') return false;
      return (str.match(/\//g) || []).length === 2;      
    },    
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 355
    },    
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    formatDateTime(date){
      return Moment(date).format('MM/DD/YY h:mma')
    }, 
    debouncedSearch: debounce(function(event) {
      this.searchValueItems = event.target._value
    }, 500),
    updateSalesOrderEta(){
      this.sales_order_eta_popup = true
    },
    closeUpdateSalesOrderEtaPopUp(){
      this.sales_order_eta_popup = false
    },    
  }
}
</script>

<style>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .line-header{
    font-weight: bold;
  }
  .note-container{
    border-bottom: 1px dashed lightgray;
  }
  .note{
    font-size: 12px;
  }
  .note-detail{
    font-style: italic;
    font-size: 10px;
  }
</style>


