<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="540px" style="min-height:400px">
      <v-card-text >
      
        <v-card-title style="margin-left:-15px">ETA for Sales Order {{salesorder}}</v-card-title>

        <v-row style="max-width:520px;height:400px">
          <v-col cols="12">
            <datepicker v-model="eta" format="M/dd/yyyy" name="index" position="left" style="margin-bottom:15px;"></datepicker>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveEta()" :disabled="eta === '0/0/00'">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Datepicker from 'vuejs3-datepicker';

export default {
  name: 'UpdateSalesOrderEtaPopup',
  components: {Datepicker},
  props:{
    salesorder: String,
  },
  data(){
    return{
      dialog: true,
      eta: '0/0/00'
    }
  },
  beforeMount(){
    console.log(this.user)
    // var item_clone = JSON.parse(JSON.stringify( this.db.purchase_orders[this.po].items[this.itemkey] ))
    // this.item = item_clone
  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveEta(){
      var self = this
      var formatted_eta = Moment(self.eta).format('M/DD/YY')
      var eta_set_timestamp = Date.now()
      var user = self.user
      firebase.database().ref('sales_orders').child(self.salesorder).child('eta').set( formatted_eta )
      firebase.database().ref('sales_orders').child(self.salesorder).child('eta_last_updated').set( eta_set_timestamp )
      firebase.database().ref('sales_orders').child(self.salesorder).child('eta_last_updated_by').set( user )
      firebase.database().ref('sales_orders').child(self.salesorder).child('eta_history').push( {'eta': formatted_eta, 'date_provided': eta_set_timestamp, 'set_by': user} )
      self.$emit('close')
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
  .vuejs3-datepicker__icon{
    padding-top: 2px;
  }
  .vuejs3-datepicker__value{
    padding: 5px 15px;
  }
  .vuejs3-datepicker__calendar{

  }
  .vuejs3-datepicker__calendar-topbar{
    display: none;
  }
</style>


