<template>
  <div class="data_administration">
    <div style="font-size: 22px; color: gray;padding:10px">Data Administration</div>

    <div style="font-size: 18px; color: gray;padding:0px 10px;font-style:italic">Inform reports are sent to the cloud daily at 4:00pm</div>

    <div style="margin: 10px">
      <v-row>
        <v-col cols="12" md="6">
          <v-card elevation="2" class="px-4 py-3">
            <VendorsImport/>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="2" class="px-4 py-3">
            <PurchaseOrdersImport/>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="2" class="px-4 py-3">
            <CustomersImport/>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card elevation="2" class="px-4 py-3">
            <BackordersImport/>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card elevation="2" class="px-4 py-3">
            <QuotesImport/>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card elevation="2" class="px-4 py-3">
            <InvoicesImport/>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card elevation="2" class="px-4 py-3">
            <ARImport/>
          </v-card>
        </v-col>
        
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import PurchaseOrdersImport from '@/components/data_imports/purchase_orders_import.vue'
import CustomersImport from '@/components/data_imports/customers_import.vue'
import VendorsImport from '@/components/data_imports/vendors_import.vue'
import BackordersImport from '@/components/data_imports/backorders_import.vue'
import QuotesImport from '@/components/data_imports/quotes_import.vue'
import InvoicesImport from '@/components/data_imports/invoices_import.vue'
import ARImport from '@/components/data_imports/ar_import.vue'

export default {
  name: 'DataAdministration',
  components: {
    PurchaseOrdersImport,
    CustomersImport,
    VendorsImport,
    BackordersImport,
    ARImport,
    QuotesImport,
    InvoicesImport
  },
  data(){
    return {

    }
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['user','db'])
  },
  methods: {
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
  }
}
</script>

<style scoped>


</style>
