<template>
  <div class="unconverted_quotes">
    <div style="margin: 10px;">


<!-- handle SP and Duplicates in data import -->
<!-- notify employee if exclusion is rejected -->
<!-- default Order Writer filter to employee signed in -->
<!-- default Conversion Status filter to 'Active' -->

      <v-row class="mt-2 mb-2">
        <!-- Filter Section -->
        <v-col cols="6">
          <v-card class="pa-2 pt-8" elevation="2" style="min-height: 324px;">
          <v-row>

            <v-col cols="12">
              <div class="date-range-picker-container">
                <div class="date-range-picker-label">Date Range</div>
                <date-picker
                  v-model:value="filters.date_range"
                  type="date"
                  range
                  placeholder="All"
                  format="M/D/YYYY"
                  class="mb-4"
                  style="width: 100%;"
                ></date-picker>            
              </div>

              <v-select
                v-model="filters.ow.selected"
                :items="filters.ow.options"
                label="Order Writer"
                multiple
                variant="outlined"
                density="compact"
                hide-details
                class="mb-4"
                @update:modelValue="handleOwSelection"
              ></v-select>

              <v-select
                v-model="filters.ae.selected"
                :items="filters.ae.options"
                label="AE"
                multiple
                variant="outlined"
                density="compact"
                hide-details
                class="mb-4"
                @update:modelValue="handleAeSelection"
              ></v-select>

              <v-autocomplete
                v-model="filters.customer.selected"
                :items="filters.customer.options"
                label="Customer"
                multiple
                variant="outlined"
                density="compact"
                hide-details
                class="mb-4"
                @update:modelValue="handleCustomerSelection"
              ></v-autocomplete>

              <v-row>
              <v-col cols="6">
              <v-select
                v-model="filters.status.selected"
                :items="filters.status.options"
                label="Conversion Status"
                variant="outlined"
                density="compact"
                hide-details
                class="mb-4"
              ></v-select>
              </v-col>
              <v-col cols="6">
              <v-select
                v-model="filters.exclusion_approval.selected"
                :items="filters.exclusion_approval.options"
                label="Exclusion Approval"
                variant="outlined"
                density="compact"
                hide-details
                class="mb-4"
              ></v-select>
              </v-col>    
              </v-row> 
            </v-col>
          </v-row>
          </v-card>
        </v-col>

        <!-- Dashboard Section -->
        <v-col cols="6">
          <v-row>
            <v-col cols="6">
              <v-card class="pa-1 text-center" elevation="2" style="min-height: 70px;">
                <v-icon size="22" class="mb-0 text-grey-darken-2">mdi-file-document</v-icon>
                <div class="text-caption font-weight-medium" style="color: #4a4a4a;">QUOTES</div>
                <div class="text-h6 font-weight-regular text-blue">{{ stats.quote_cnt }}</div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-1 text-center" elevation="2" style="min-height: 70px;">
                <v-icon size="22" class="mb-0 text-grey-darken-2">mdi-format-list-numbered</v-icon>
                <div class="text-caption font-weight-medium" style="color: #4a4a4a;">LINE-ITEMS</div>
                <div class="text-h6 font-weight-regular text-blue">{{ stats.line_cnt }}</div>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="6">
              <v-card class="pa-1 text-center" elevation="2" style="min-height: 70px;">
                <v-icon size="22" class="mb-0 text-grey-darken-2">mdi-check-bold</v-icon>
                <div class="text-caption font-weight-medium" style="color: #4a4a4a; white-space: nowrap;">
                  LINE-ITEMS CONVERTED
                </div>
                <div class="text-h6 font-weight-regular text-success">{{ stats.converted_cnt }}</div>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card class="pa-1 text-center" elevation="2" style="min-height: 70px;">
                <v-icon size="22" class="mb-0 text-grey-darken-2">mdi-percent</v-icon>
                <div class="text-caption font-weight-medium" style="color: #4a4a4a;">CONVERSION RATE</div>
                <div class="text-h6 font-weight-regular text-orange">{{ stats.conversion_rate }}</div>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12">
              <v-card class="pa-1 text-center" elevation="2" style="min-height: 10px;">
                <span style="color:red;font-style:italic;font-size:12px">Approved exclusions are not included in the above numbers</span>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12">
              <v-card class="pa-2 text-left pt-3 pb-0" elevation="2" style="">

                <v-row>
                <v-col cols="6">
                  <QuotesImport/>
                </v-col>   

                <v-col cols="6">
                  <InvoicesImport/>
                </v-col>   
              </v-row>
            
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <Vue3EasyDataTable
        :headers="headers"
        :items="quotes_table_data"
        :sort-by="sortBy"
        :sort-type="sortType"
        :table-height="table_height"
        :rows-per-page="250"
        :rows-items="[250, 500, 1000, 5000, 10000, 15000, 20000]"
        :hide-rows-per-page="false"
        :hide-footer="false"
        multi-sort
      >

      <template #item-order_date_sortable="item">
        <div>{{item.order_date}}</div>
      </template>

      <template #item-conversion_status="item">
        <span style="font-size:10px" v-if="item.converted"><i class="mdi mdi-check"></i>{{item.conversion_status}}</span>
        <span v-if="!item.converted" style='color:#ff7214;cursor:pointer;font-weight:normal;font-size:10px' @click="openConversionStatusUpdatePopup(item.id)"><i class="mdi mdi-alert" v-if="item.conversion_status === 'Active' || item.conversion_status === 'Expired'"></i>{{item.conversion_status}}</span>
      </template>

      <template #item-working_notes="item">
        <div v-for="n in item.working_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
          <div style="font-style:italic">{{n.note}}</div>
          <div style="font-size:8px;line-height:100%;color:gray;">{{formatDate(n.timestamp)}} by {{db.users[n.user].initials}}</div>
        </div>
        <v-btn style="background:white;color:#ff7214;height:15px;width:40px;font-size:8px;margin-bottom: 5px" @click="openNotePopup(item.id)">ADD NOTE</v-btn>
      </template>

      <template #item-exclusion_approval_status_sortable="item">
        <v-btn :disabled="!is_admin" v-if="item.exclusion_approval_status_sortable.includes('Needs Review')" style="background:red;color:white;height:15px;width:85px;font-size:8px;margin-bottom: 5px;font-weight: bold" @click="openConversionExcludeApprovalPopup(item.id)">{{item.exclusion_approval_status_sortable}}</v-btn>
        <v-btn :disabled="!is_admin" v-if="item.exclusion_approval_status_sortable.includes('Approved')" style="background:white;color:green;height:15px;width:85px;font-size:8px;margin-bottom: 5px;font-weight: bold" @click="openConversionExcludeApprovalPopup(item.id)">{{item.exclusion_approval_status_sortable}}</v-btn>
        <v-btn :disabled="!is_admin" v-if="item.exclusion_approval_status_sortable.includes('Rejected')" style="background:white;color:red;height:15px;width:85px;font-size:8px;margin-bottom: 5px;font-weight: bold" @click="openConversionExcludeApprovalPopup(item.id)">{{item.exclusion_approval_status_sortable}}</v-btn>
      </template>

    </Vue3EasyDataTable>
    <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;float:right;margin-top:5px" @click="exportTable()" :disabled="false">EXPORT</v-btn>    
    </div>
    <ConversionStatusUpdatePopUp v-if="update_conversion_status" :quote_id='quote_for_component' @close="closeConversionStatusUpdatePopup()" />

    <ConversionExcludeApprovalPopUp v-if="update_conversion_exclude_status" :quote_id='quote_for_component' @close="closeConversionExcludeApprovalPopup()" />

    <ConversionNotePopUp v-if="add_note" :quote_id='quote_for_component' @close="closeNotePopup()" />
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import { debounce } from 'lodash';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import ConversionStatusUpdatePopUp from '@/components/ConversionStatusUpdatePopUp.vue';
import ConversionExcludeApprovalPopUp from '@/components/ConversionExcludeApprovalPopUp.vue';

import ConversionNotePopUp from '@/components/ConversionNotePopUp.vue';
import DatePicker from 'vue-datepicker-next';
import QuotesImport from '@/components/data_imports/quotes_import.vue'
import InvoicesImport from '@/components/data_imports/invoices_import.vue'
import XLSX from 'xlsx'

export default {
  name: 'ConversionTrackerView',
  components: {Vue3EasyDataTable,ConversionStatusUpdatePopUp,ConversionExcludeApprovalPopUp,ConversionNotePopUp,DatePicker,QuotesImport,InvoicesImport},
  data(){
    return {
      sortBy: ['order_date_sortable'],
      sortType: ['asc'],
      update_conversion_status: false,
      update_conversion_exclude_status: false,
      add_note: false,
      quote_for_component: null,
      filters: {
        ow: {options:['All'],selected: ['All']},
        ae: {options:['All'],selected: ['All']},
        customer: {options:['All'], selected: ['All']},
        date_range: [],
        exclusion_approval: {options:['All','Needs Review', 'Rejected', 'Approved'],selected: 'All'},
        status: {options: ['All', 'Converted', 'Unconverted', 'Active', 'Expired', 'Lost', 'Exclude', 'Lost - Price', 'Lost - Lead Time', 'Lost - Doesn\'t Meet Specs', 'Lost - No Longer Needed', 'Lost - Other', 'Exclude - General Inquiry', 'Exclude - Pricing List', 'Exclude - Converted On Different Order', 'Exclude - Other'], selected: 'All'}
      },
      table_height: 300,
      stats: {
        quote_cnt: 0,
        line_cnt: 0,
        converted_cnt: 0,
        conversion_rate: 0
      },
      headers: [
        { text: "QQ#", value: "order_number", sortable: true },
        { text: "Quote Date", value: "order_date_sortable", sortable: true },
        { text: "OW Initials", value: "order_writer", sortable: true },
        { text: "AE Initials", value: "salesman_code", sortable: true },
        { text: "Dollar Amt.", value: "extension", sortable: true },
        { text: "Product #", value: "product", sortable: true },
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Conversion Status", value: "conversion_status", sortable: true },
        { text: "Working Notes", value: "working_notes", sortable: false },
        { text: "Exclusion Approval", value: "exclusion_approval_status_sortable", sortable: true },
      ]
    }
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }
    this.filters.date_range = [Moment().startOf('year').toDate(), Moment().endOf('day').toDate()];
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  }, 
  computed: {
    ...mapGetters(['user','db','is_admin']),
    lastQuotesFileUpload(){
      return this.db.last_quotes_file_upload ? Moment(this.db.last_quotes_file_upload).format('MM/DD/YY hh:mma') : false
    },
    lastInvoicesFileUpload(){
      return this.db.last_invoices_file_upload ? Moment(this.db.last_invoices_file_upload).format('MM/DD/YY hh:mma') : false
    },
    quotes_table_data() {
      var table_data = []
      var quotes = JSON.parse(JSON.stringify( this.db.quotes )) || {}
      var invoices = JSON.parse(JSON.stringify( this.db.invoices )) || {}

      var line_item_cnt = 0
      var converted_cnt = 0

      var unique_quote_numbers = new Set();
      var unique_order_writers = new Set();
      var unique_salesman_codes = new Set();
      var unique_customers = new Set();
      
      for (var q in quotes) {
        var quote = quotes[q]

        if (quote.order_writer) unique_order_writers.add(quote.order_writer);
        if (quote.salesman_code) unique_salesman_codes.add(quote.salesman_code);
        if (quote.customer_name) unique_customers.add(quote.customer_name);

        quote.id = q

        quote.working_notes = quote.working_notes ? quote.working_notes : ''

        quote.order_date_sortable = Moment(quote.order_date).startOf('day').valueOf();
        quote.converted = invoices[q] ? true : false

        if (quote.converted) {
          quote.conversion_status = 'Converted';
        } else if (quote.unconverted_reason) {
          quote.conversion_status = quote.unconverted_reason;
        } else {
          const expirationDate = Moment(quote.order_date).add(1, 'months').endOf('month'); // Get last day of next month
          quote.conversion_status = Moment().isAfter(expirationDate) ? 'Expired' : 'Active';
        }

        quote.exclusion_approval_status_sortable = !quote.converted && quote.exclusion_approval_status ? quote.exclusion_approval_status : '';

        const lostStatuses = [
          'Lost - Price', 'Lost - Lead Time', 'Lost - Doesn\'t Meet Specs', 
          'Lost - No Longer Needed', 'Lost - Other'
        ];

        const excludeStatuses = [
          'Exclude - General Inquiry', 'Exclude - Pricing List', 
          'Exclude - Converted On Different Order', 'Exclude - Other'
        ];

        // Filtering logic for `this.filters.status`
        const matchesStatus = (
          this.filters.status.selected === 'All' ||
          (this.filters.status.selected === 'Converted' && quote.conversion_status === 'Converted') ||
          (this.filters.status.selected === 'Unconverted' && quote.conversion_status !== 'Converted') ||
          (this.filters.status.selected === 'Active' && quote.conversion_status === 'Active') ||
          (this.filters.status.selected === 'Expired' && quote.conversion_status === 'Expired') ||
          (this.filters.status.selected === 'Lost' && lostStatuses.includes(quote.conversion_status)) ||
          (this.filters.status.selected === 'Exclude' && excludeStatuses.includes(quote.conversion_status)) ||
          this.filters.status.selected === quote.conversion_status // Specific statuses like "Lost - Price"
        );
        const matchesExclusionApproval = (
          this.filters.exclusion_approval.selected === 'All' ||
          (this.filters.exclusion_approval.selected === 'Approved' && quote.exclusion_approval_status === 'Approved') ||
          (this.filters.exclusion_approval.selected === 'Rejected' && quote.exclusion_approval_status === 'Rejected') ||
          (this.filters.exclusion_approval.selected === 'Needs Review' && quote.exclusion_approval_status === 'Needs Review')
        );

        var start_sortable = Moment(this.filters.date_range[0]).startOf('day').valueOf()
        var end_sortable = Moment(this.filters.date_range[1]).endOf('day').valueOf()

        const matchesDateRange = (!start_sortable || !end_sortable || (quote.order_date_sortable >= start_sortable && quote.order_date_sortable <= end_sortable));

        const matchesOrderWriter = this.filters.ow.selected.includes("All") || 
                                   this.filters.ow.selected.includes(quote.order_writer);

        const matchesSalesmanCode = this.filters.ae.selected.includes("All") || 
                                    this.filters.ae.selected.includes(quote.salesman_code);

        const matchesCustomer = this.filters.customer.selected.includes("All") || 
                                    this.filters.customer.selected.includes(quote.customer_name);

        if (matchesDateRange && matchesOrderWriter && matchesSalesmanCode && matchesCustomer && matchesStatus && matchesExclusionApproval) {
          table_data.push(quote);

          if (quote.converted) {
            converted_cnt++;
          }

          if (!unique_quote_numbers.has(quote.order_number) && quote.exclusion_approval_status !== 'Approved') {
            unique_quote_numbers.add(quote.order_number);
          }

          if(quote.exclusion_approval_status !== 'Approved'){
            line_item_cnt++;  
          }
          
        }
      }

      // Update dropdown options dynamically
      this.filters.ow.options = ['All', ...Array.from(unique_order_writers).sort()];
      this.filters.ae.options = ['All', ...Array.from(unique_salesman_codes).sort()];
      this.filters.customer.options = ['All', ...Array.from(unique_customers).sort()];

      this.stats.quote_cnt = unique_quote_numbers.size.toLocaleString();
      this.stats.line_cnt = line_item_cnt.toLocaleString();
      this.stats.converted_cnt = converted_cnt.toLocaleString();
      this.stats.conversion_rate = line_item_cnt > 0 ? (converted_cnt / line_item_cnt * 100).toFixed(2) + '%' : '0%';

      return table_data;
    }
  },
  methods: {
    exportTable(){
      var items = this.quotes_table_data
      var export_arr = []

      for(var i = 0; i < items.length; i++){
        var item = items[i]
        var row_item = {
          'QQ#' : item.order_number,
          'Quote Date': item.order_date,
          'OW Initials': item.order_writer,
          'AE Initials': item.salesman_code,
          'Dollar Amt.': item.extension,
          'Product #': item.product,
          'Customer': item.customer_name,
          'Conversion Status': item.conversion_status,
          'Working Notes': '',
          'Exclusion Approval': item.exclusion_approval_status_sortable
        }

        if (item.working_notes && typeof item.working_notes === 'object') {
          let notesArray = Object.values(item.working_notes).map(n => 
            `${n.note} (${this.db.users[n.user]?.initials || 'N/A'} on ${this.formatDate(n.timestamp)})`
          );

          row_item['Working Notes'] = notesArray.join(', ');
        }

        export_arr.push(row_item)
      }

      var wscols = [
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:15},
          {wch:30},
          {wch:40},
          {wch:20},
          {wch:50},
          {wch:20}
      ];

      var worksheet = XLSX.utils.json_to_sheet(export_arr)
      worksheet['!cols'] = wscols;
      var workbook = XLSX.utils.book_new()
      var export_date = Date.now()
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'conversion_tracker_' + export_date + '.xlsx')
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 495
    },    
    openNotePopup(quote){
      this.quote_for_component = quote
      this.add_note = true
    },
    closeNotePopup(){
      this.add_note = false
      this.quote_for_component = null
    },
    openConversionStatusUpdatePopup(quote){
      this.quote_for_component = quote
      this.update_conversion_status = true
    },
    openConversionExcludeApprovalPopup(quote){
      this.quote_for_component = quote
      this.update_conversion_exclude_status = true
    },
    closeConversionStatusUpdatePopup(){
      this.update_conversion_status = false
      this.quote_for_component = null
    },
    closeConversionExcludeApprovalPopup(){
      this.update_conversion_exclude_status = false
      this.quote_for_component = null
    },    
    debouncedApplyFilters: debounce(function() {
      this.filters.date_range = JSON.parse(JSON.stringify(this.pending_date_range_filter));
    }, 500),
    handleOwSelection(currently_selected) {
      var last_selected_option = currently_selected[currently_selected.length-1]
      if(currently_selected.length > 1 && currently_selected.includes('All') && last_selected_option !== 'All'){
        currently_selected = currently_selected.filter(item => item !== "All");
      }
      if(last_selected_option === 'All'){
        currently_selected = ['All']
      }
      this.filters.ow.selected = currently_selected;
    },
    handleAeSelection(currently_selected) {
      var last_selected_option = currently_selected[currently_selected.length-1]
      if(currently_selected.length > 1 && currently_selected.includes('All') && last_selected_option !== 'All'){
        currently_selected = currently_selected.filter(item => item !== "All");
      }
      if(last_selected_option === 'All'){
        currently_selected = ['All']
      }
      this.filters.ae.selected = currently_selected;
    },
    handleCustomerSelection(currently_selected) {
      var last_selected_option = currently_selected[currently_selected.length-1]
      if(currently_selected.length > 1 && currently_selected.includes('All') && last_selected_option !== 'All'){
        currently_selected = currently_selected.filter(item => item !== "All");
      }
      if(last_selected_option === 'All'){
        currently_selected = ['All']
      }
      this.filters.customer.selected = currently_selected;
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value
    }, 500),    
    formatDate(date){
      return Moment(date).format('MM/DD/YY h:mm A')
    },
  }
}
</script>

<style scoped>

  .date-range-picker-container{
    position: relative;
  }

  .date-range-picker-label{
    color: gray;
    font-size: 12px;
    background: white;
    position: absolute;
    top: -8px;
    left: 15px;
    z-index: 1;
    width: 68px;
    padding-left: 2px;    
  }

  ::v-deep(.multi-sort__number){
    text-align:center;
    min-height: 1.5em;
    min-width: 1.5em;
  }

  ::v-deep.small-dropdown .v-label.v-field-label {
    font-size: 10px;
  }

  ::v-deep.small-dropdown .v-select__selection-text {
    font-size: 14px;
  }

  ::v-deep.small-dropdown .v-field__input {
    padding-top:10px;
  }

  .mdi{
    padding-right:2px;
  }

</style>
