<template>
  <div class="login">
    <v-card color="orange" elevation="20" width="400px">
      <v-img
        :src="fleet_img"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      >
        <v-card-title>Riverhawk Nest</v-card-title>
      </v-img>

      <v-card-text>
        <v-text-field v-model="email" placeholder="email" @keyup="clearError"></v-text-field>
        <v-text-field v-model="password" placeholder="password" type="password" @keyup="clearError"></v-text-field>
        <v-btn color="gray" width="100%" @click="login">LOGIN</v-btn>
      </v-card-text>
      <v-card-text>{{loginError}}</v-card-text>
    </v-card>
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';

export default {
  name: 'LoginView',
  components: {},
  data(){
    return{
      fleet_img: require('@/assets/fleet.jpeg'),
      email: '',
      password: '',
      loginError: null
    }
  },
  mounted(){
    var vm = this;
    document.addEventListener('keydown',vm.isEnter, true);
    firebase.auth().signOut()
  },
  beforeDestroy(){
    var vm = this;
    document.removeEventListener('keydown',vm.isEnter, true);
  },
  methods:{
    isEnter(event){
      // event.key === 'Enter' ? this.login() : false
    },
    clearError(event){
      // event.key !== 'Enter' ? this.loginError = null : false
    },
    login() {
      var self = this

      firebase.auth().signInWithEmailAndPassword(self.email, self.password).then(user => {
        self.$router.push('/purchasing-dashboard')
      }).catch(err => {
        console.log(err)
        self.loginError = err["message"]
        self.$router.push('/')
      })
    }
  }
}
</script>

<style scoped>

  .v-card{
    margin: 0 auto;
  }
  .login {
    background: primary;
  }
</style>

