<template>
  <div class="quotes-import">

    <div class="dropzone bg-white" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input type="file" name="fields[assetsFieldHandle_v][]" id="assetsFieldHandle_v" @change="onChange" ref="file" style="opacity: 0;display:none"/>
      <div v-if="!filelist.length" style="padding-top:10px;padding-left:25px;color:gray;display:flex;align-items:center;">
        <v-icon class="mdi mdi-file-upload-outline orange--text"></v-icon>
        <span style="padding-left:10px;">Quotes File Drop</span>
      </div>
    </div>
    <div style="color:red;font-size:10px;font-weight:bold;padding-top:5px;color:white">.</div>
    <div style="display:flex;justify-content:space-between;align-items:center;margin-top:5px;position:relative;">
      <div style="position:absolute;right:0px;bottom:2px">
        <div style="font-size:12px;color:gray;font-style:italic;">Last Import: {{lastUploadDate}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as XLSX from "xlsx"
import Papa from "papaparse"
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import AWS from 'aws-sdk';

export default {
  name: 'QuotesImport',
  components: {

  },
  data(){
    return {
      filelist: []
    }
  },
  watch: {
    filelist(val){
      if(val.length > 0){
        this.readFiles()
      }
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    lastUploadDate(){
      return this.db.last_quotes_file_upload ? Moment(this.db.last_quotes_file_upload).format('MM/DD/YY hh:mma') : false
    }
  },
  methods: {
    async pullData(){
      alert('WIP')
      // AWS.config.region = 'us-east-1';

      // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      //   IdentityPoolId: 'us-east-1:44b121e3-3117-4e3a-b0b1-a9ea0abf7772',
      // });

      // AWS.config.credentials.get(() => {
      //   const s3 = new AWS.S3();

      //   const params = {
      //     Bucket: 'cloudmailing-files',
      //     Prefix: 'quotes/'
      //   };

      //   s3.listObjectsV2(params, (err, data) => {
      //     if (err) {
      //       console.error(err);
      //     } else {
      //       const files_arr = data['Contents'].sort((a, b) => (a.LastModified < b.LastModified) ? 1 : ((b.LastModified < a.LastModified) ? -1 : 0));
      //       const fileKey = files_arr[0].Key;
      //       const s3Params = { Bucket: 'cloudmailing-files', Key: fileKey };

      //       var most_recent_file_date = Moment(new Date(files_arr[0].LastModified));
      //       var today_date = Moment(new Date());
      //       var is_same_day = most_recent_file_date.isSame(today_date, 'day');
      //       var shouldProceed = is_same_day;
      //       var file_date = Moment(files_arr[0].LastModified, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').valueOf();

      //       if (!is_same_day) {
      //           var msg = 'This file is from ' + most_recent_file_date.format('MM-DD-YYYY') + '. Today\'s file has not loaded to the cloud yet. Do you want to proceed with loading the older file?'
      //           shouldProceed = confirm(msg);
      //       }

      //       if (shouldProceed) {
      //         s3.getObject(s3Params, (err, data) => {
      //           if (err) {
      //             console.error(err);
      //           } else {
      //             const file = new File([data.Body], fileKey);
      //             this.writeInitialData(file,file_date);
      //           }
      //         });
      //       }
      //     }
      //   });
      // });
    },
    searchFiles(){
      document.getElementById("assetsFieldHandle_v").click()
    },
    onChange() {
      var files_arr = [...this.$refs.file.files];
      files_arr = _.sortBy(files_arr, (obj) => parseInt(obj.name, 10));
      this.filelist = files_arr;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-orange')) {
        event.currentTarget.classList.remove('bg-white');
        event.currentTarget.classList.add('bg-orange');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
      this.onChange();
    },
    readFiles(){
      for(var i = 0; i < this.filelist.length; i++){
        var file_name = this.filelist[i].name.toLowerCase()
        if(this.filelist[i].type.indexOf('text/csv') !== -1 && file_name.indexOf('quotes') !== -1){
          this.writeInitialData(this.filelist[i], Date.now())
        }else{
          this.$store.state.isLoading = false
          this.remove(this.filelist[i])
          alert("Invalid File - File name must contain 'quotes'")
        }
      }
    },
    writeInitialData(file,file_date){
      var reader = new FileReader();
      var self = this
      self.$store.state.isLoading = true
      reader.readAsBinaryString(file);

      reader.onload = function(e) {
        var data = e.target.result;
        var parsedData = Papa.parse(data, {
          header: true, // Set header to true to parse the first row as headers
          dynamicTyping: false, // Disable automatic type casting
          skipEmptyLines: true, // Skip empty lines
        });

        var json = parsedData.data;

        if (json.length === 0) {
          self.$store.state.isLoading = false;
          alert('Invalid File(s)');
          return;
        }

        setTimeout(function(){
          self.updateDatabase(json,file_date)
        },1000)
      }
    },
    updateDatabase(json,file_date){
      var self = this
      var db_object = {}
      var current_db_quotes_clone = self.db.quotes ? JSON.parse(JSON.stringify(self.db.quotes)) : {}

      for(var i = 0; i < json.length; i++){
        var row_data = json[i]
        var unique_id = row_data['Order Number'] + '_' + row_data['Product (25)'].replace(/[^0-9a-z]/gi, '');

        var quote_info = {
          'order_date': row_data['Order Date'] || '',
          'order_number': row_data['Order Number'] || '',
          'invoice_date': row_data['Invoice Date'] || '',
          'invoice_number': row_data['Invoice Number'] || '',
          'product': row_data['Product (25)'] || '',
          'product_description': row_data['Product Description'] || '',
          'product_line_code': row_data['Product Line Code'] || '',
          'price_override': row_data['Price Override'] || '',
          'price_method': row_data['Price Method'] || '',
          'original_net_price': row_data['Original Net Price'] || '',
          'net_price': row_data['Net Price'] || '',
          'product_price_override': row_data['Product Price Override'] || '',
          'product_price_method': row_data['Product Price Method'] || '',
          'contract_price_method': row_data['Contract Price Method'] || '',
          'calculated_price_method': row_data['Calculated Price Method'] || '',
          'calculated_price': row_data['Calculated Price'] || '',
          'unit_price': row_data['Unit Price'] || '',
          'complete_order_status': row_data['Complete Order Status'] || '',
          'customer_name': row_data['Customer Name'] || '',
          'customer_number': row_data['Customer Number'] || '',
          'customer_master_ar_account': row_data['Customer Master AR Account'] || '',
          'customer_macro_account_number': row_data['Customer Macro Account Number'] || '',
          'customer_category': row_data['Customer Category'] || '',
          'contract_price_method_description': row_data['Contract Price Method Description'] || '',
          'description': row_data['Description'] || '',
          'order_writer': row_data['Order Writer'] || '',
          'salesman_code': row_data['Salesman Code'] || '',
          'extension': row_data['Extension'] || '',
          'net_cost': row_data['Net Cost'] || '',
          'line_item_gross_profit_percent': row_data['Line Item Gross Profit Percent'] || '',
          'so_gp_dollars': row_data['SO GP Dollars'] || '',
          'so_gp_percent': row_data['SO GP Percent'] || '',
          'cancelled_order': row_data['Cancelled Order'] || '',
          'total_order_value': row_data['Total Order Value'] || '',
          'open_amount': row_data['Open Amount'] || '',
          'total_cost': row_data['Total Cost'] || '',
          'invoice_line_extension': row_data['Invoice Line Extension'] || '',
          'product_extended_cost': row_data['Product Extended Cost'] || '',
          'special_inst_flat': row_data['Special Inst Flat'] || '',
          'converted_quote_number': row_data['Converted Quote Number'] || '',
          'internal_comments_flat': row_data['Internal Comments Flat'] || '',
          'external_comments_flat': row_data['External Comments Flat'] || '',
          'price_contract': row_data['Price Contract'] || '',
          'contract_number': row_data['Contract Number'] || '',
          'effective_date': row_data['Effective Date'] || '',
          'expiration_date': row_data['Expiration Date'] || '',
          'branch': row_data['Branch'] || '',
          'line_item_warehouse': row_data['Line Item Warehouse'] || '',
          'upload_date': Date.now(),
          'upload_by': self.user,
          'unique_id': unique_id
        };

        if (current_db_quotes_clone[unique_id]) {
          db_object[unique_id] = { ...current_db_quotes_clone[unique_id], ...quote_info }; // Preserve existing custom fields
        } else {
          db_object[unique_id] = quote_info;
        }
      }

      firebase.database().ref('quotes').update(db_object);
      firebase.database().ref('last_quotes_file_upload').set(Date.now()).then(function(){console.log('fdafda')})
      self.filelist = []
      self.$store.state.isLoading = false
    }
  }
}
</script>

<style scoped>
  .dropzone{
    width: 100%;
    height: 50px;
    border: 2px dashed orange;
    border-radius: 4px;
  }
  .bg-white{
    background: white;
  }
  .bg-orange{
    background: orange;
  }
</style>
