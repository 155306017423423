<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="540px" style="min-height:300px;">
      <v-card-text >
      
        <v-card-subtitle style="margin-left:-15px;color:black;font-size:16px">Quote: {{order_number}}</v-card-subtitle>
        <v-card-subtitle style="margin-left:-15px;color:black;font-size:16px">Product: {{product}}</v-card-subtitle>

        <v-row style="max-width:520px;height:300px">
          <v-col cols="12">

            <v-textarea style="margin-top:10px;line-height: 100%;" variant="outlined" no-resize height="10" :hide-details="true" :label="'Working Note'" v-model="working_note"></v-textarea>

            <v-checkbox color="#ff7214" variant="solo" density="compact" :hide-details="true" v-model="apply_to_all_items_on_quote" :label="'Apply to all items on quote?'"></v-checkbox>

          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="save()" :disabled="isSaveDisabled">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ConversionNotePopUp',
  components: {},
  props:{
    quote_id: String,
  },
  data(){
    return{
      dialog: true,
      order_number: null,
      product: null,
      working_note: '',
      apply_to_all_items_on_quote: false,
    }
  },
  beforeMount(){
    var quote = this.db.quotes[this.quote_id]
    this.order_number = quote.order_number
    this.product = quote.product
  },
  computed: {
    ...mapGetters(['user','db']),
    isSaveDisabled() {
      return !this.working_note.trim().length > 0
    }
  },
  methods:{
    save(){
      var self = this;
      var timestamp = Date.now();
      var updates_for_db = {};

      if (self.apply_to_all_items_on_quote) {
        firebase.database().ref('quotes').orderByChild('order_number').equalTo(self.order_number).once('value', function(snapshot) {
          var data = snapshot.val();
          Object.keys(data).forEach(addQuoteUpdate);
          applyUpdates();
        });
      } else {
        addQuoteUpdate(self.quote_id);
        applyUpdates();
      }

      function addQuoteUpdate(quoteId) {
        updates_for_db[`/quotes/${quoteId}/working_notes/${timestamp}`] = {
          user: self.user,
          timestamp: timestamp,
          note: self.working_note.trim()
        };
      }

      function applyUpdates() {
        firebase.database().ref().update(updates_for_db)
        .then(() => self.$emit('close'))
        .catch(error => console.error("Error updating quotes:", error));
      }
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
</style>


