<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="540px" style="min-height:300px;">

      <v-card-text>
        <v-card-subtitle style="margin-left:-15px;color:black;font-size:16px">Quote: {{order_number}}</v-card-subtitle>
        <v-card-subtitle style="margin-left:-15px;color:black;font-size:16px">Product: {{product}}</v-card-subtitle>
        <v-card-subtitle style="margin-left:-15px;color:black;font-size:16px">Approve or Reject: <span style="font-style: italic;">{{exclusion_reason}}</span></v-card-subtitle>
        
        <v-row dense style="max-width: 520px;margin-top:10px">
          <v-col cols="6">
            <v-btn block :color="decision === 'approve' ? 'green' : 'lightgrey lighten-2'" :class="decision === 'approve' ? 'text-white' : 'text-black'" @click="toggleDecision('approve')">
              APPROVE EXCLUSION
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block :color="decision === 'reject' ? 'red' : 'lightgrey lighten-2'" :class="decision === 'reject' ? 'text-white' : 'text-black'" @click="toggleDecision('reject')">
              REJECT EXCLUSION
            </v-btn>
          </v-col>
        </v-row>

        <v-row dense style="max-width:520px;">
          <v-col cols="12">
            <v-textarea
              style="margin-top:10px;line-height: 100%;"
              variant="outlined"
              no-resize
              height="10"
              :hide-details="true"
              :label="decision === 'reject' ? 'Please provide reason for rejection' : 'Working Note'"
              v-model="working_note"
            ></v-textarea>
            <v-checkbox
              color="#ff7214"
              variant="solo"
              density="compact"
              :hide-details="true"
              v-model="apply_to_all_excluded_items_on_quote"
              :label="'Apply to all other Excluded items on quote?'"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="save()" :disabled="isSaveDisabled">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ConversionExcludeApprovalPopUp',
  components: {},
  props:{
    quote_id: String,
  },
  data(){
    return{
      dialog: true,
      order_number: null,
      product: null,
      apply_to_all_excluded_items_on_quote: false,
      working_note: '',
      decision: '', // 'approve' or 'reject'
      previously_rejected: false,
      exclusion_reason: null,
      quote: null
    }
  },
  beforeMount(){
    var quote = JSON.parse(JSON.stringify( this.db.quotes[this.quote_id] ))
    this.quote = quote
    this.order_number = quote.order_number
    this.product = quote.product
    this.exclusion_reason = quote.unconverted_reason
    
    if(quote.exclusion_approval_status === 'Rejected'){
      this.previously_rejected = true
      this.exclusion_reason = quote.rejected_exclusion
    }
    // rejected_exclusion
  },
  computed: {
    ...mapGetters(['user','db']),
    isSaveDisabled() {
      if (!this.decision) return true
      if (this.decision === 'reject' && this.working_note.trim() === '') return true
      return false
    }    
  },
  methods:{
    toggleDecision(choice) {
      this.decision = this.decision === choice ? '' : choice
    },  
    save(){
      var self = this;
      var timestamp = Date.now();
      var updates_for_db = {};
      var has_working_note = self.working_note.trim().length > 0;

      if (self.apply_to_all_excluded_items_on_quote) {
        firebase.database().ref('quotes').orderByChild('order_number').equalTo(self.order_number).once('value', function(snapshot) {
          var data = snapshot.val();

          Object.entries(data).forEach(([quoteId, quote]) => {
            // looking if quoteId is quote_id so that a rejection/approved can be flipped to something else if the unconverted_reason is no longer Exclude because it was approved or rejected   

            // APPROVE FOR ALL 
             // || quote.exclusion_approval_status === 'Rejected' && self.decision === 'approved'
            if ( quoteId === self.quote_id || (quote.unconverted_reason && quote.unconverted_reason.includes('Exclude')) || quote.rejected_exclusion && quote.exclusion_approval_status === 'Rejected' && self.decision === 'approve') {
              addQuoteUpdate(quoteId,quote);
            }
          });

          applyUpdates();
        });
      } else {
        addQuoteUpdate(self.quote_id,self.quote);
        applyUpdates();
      }

      function addQuoteUpdate(quoteId,quote) {
        const status = self.decision === 'approve' ? 'Approved' : 'Rejected';

        updates_for_db[`/quotes/${quoteId}/exclusion_approval_status`] = status;

        updates_for_db[`/quotes/${quoteId}/exclusion_approval_status_log/${timestamp}`] = {
          user: self.user,
          timestamp: timestamp,
          decision: status,
          working_note: self.working_note 
        };

        if (self.decision === 'reject') {
          updates_for_db[`/quotes/${quoteId}/unconverted_reason`] = null;
          updates_for_db[`/quotes/${quoteId}/rejected_exclusion`] = quote.unconverted_reason;
        }

        if (self.decision === 'approve' && self.previously_rejected){
          updates_for_db[`/quotes/${quoteId}/unconverted_reason`] = quote.rejected_exclusion;
        }
   
        if (has_working_note) {
          const note = self.decision === 'reject' ? `Rejected Exclusion Reason - ${self.working_note.trim()}` : self.working_note.trim();
          updates_for_db[`/quotes/${quoteId}/working_notes/${timestamp}`] = {
            user: self.user,
            timestamp: timestamp,
            note: note
          };
        }
      }

      function applyUpdates() {
        firebase.database().ref().update(updates_for_db)
        .then(() => self.$emit('close'))
        .catch(error => console.error("Error updating quotes:", error));
      }
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
</style>


