<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="540px" style="min-height:320px;">
      <v-card-text >
      
        <v-card-subtitle style="margin-left:-15px;color:black;font-size:16px">Quote: {{order_number}}</v-card-subtitle>
        <v-card-subtitle style="margin-left:-15px;color:black;font-size:16px">Product: {{product}}</v-card-subtitle>

        <v-row style="max-width:520px;height:320px;margin-top:10px">
          <v-col cols="12">
            <v-select
              v-model="conversion_status"
              :items="conversion_status_options"
              label="Conversion Status"
              variant="outlined"
              density="compact"
              class="small-dropdown"
              hide-details
            ></v-select>

            <v-textarea style="margin-top:10px;line-height: 100%;" variant="outlined" no-resize height="10" :hide-details="true" :label="'Working Note'" v-model="working_note"></v-textarea>

            <v-checkbox color="#ff7214" variant="solo" density="compact" :hide-details="true" v-model="apply_to_all_items_on_quote" :label="'Apply to all unconverted items on quote?'"></v-checkbox>

          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="save()" :disabled="isSaveDisabled">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ConversionStatusUpdatePopUp',
  components: {},
  props:{
    quote_id: String,
  },
  data(){
    return{
      dialog: true,
      order_number: null,
      product: null,
      conversion_status: null,
      initial_conversion_status: null,
      apply_to_all_items_on_quote: false,
      working_note: '',
      conversion_status_options: [   
        'Lost - Price',
        'Lost - Lead Time',
        'Lost - Doesn\'t Meet Specs',
        'Lost - No Longer Needed',
        'Lost - Other',
        'Exclude - General Inquiry',
        'Exclude - Pricing List',
        'Exclude - Converted On Different Order',
        'Exclude - Other'
      ]
    }
  },
  beforeMount(){
    var quote = this.db.quotes[this.quote_id]
    var expiration_date = Moment(quote.order_date).add(1, 'months').endOf('month'); // Get last day of next month
    var quote_expired = Moment().isAfter(expiration_date) ? true : false;
    this.product = quote.product
    this.conversion_status_options.unshift(quote_expired ? 'Expired' : 'Active')  
    this.order_number = quote.order_number
    this.conversion_status = quote.unconverted_reason || (quote_expired ? 'Expired' : 'Active');
    this.initial_conversion_status = this.conversion_status
  },
  computed: {
    ...mapGetters(['user','db']),
    isSaveDisabled() {
      const statusChanged = this.conversion_status !== this.initial_conversion_status;
      return !statusChanged && !this.apply_to_all_items_on_quote || (this.apply_to_all_items_on_quote && !this.conversion_status);
    }    
  },
  methods:{
    save(){ 
      var self = this;
      var timestamp = Date.now();
      var updates_for_db = {};
      var has_working_note = self.working_note.trim().length > 0;
      var should_flag_exclusions = self.conversion_status.includes('Exclude');
      
      if (self.apply_to_all_items_on_quote) {
        firebase.database().ref('quotes').orderByChild('order_number').equalTo(self.order_number).once('value', function(snapshot) {
          var data = snapshot.val();
          Object.keys(data).forEach(addQuoteUpdate);
          applyUpdates();
        });
      } else {
        addQuoteUpdate(self.quote_id);
        applyUpdates();
      }

      function addQuoteUpdate(quoteId) {
        updates_for_db[`/quotes/${quoteId}/unconverted_reason`] = self.conversion_status;
        updates_for_db[`/quotes/${quoteId}/converted_status_update_log/${timestamp}`] = {
          user: self.user,
          timestamp: timestamp,
          status: self.conversion_status
        };
        
        if(has_working_note){
          updates_for_db[`/quotes/${quoteId}/working_notes/${timestamp}`] = {
            user: self.user,
            timestamp: timestamp,
            note: self.working_note.trim()
          };
        }

        if(should_flag_exclusions){
          updates_for_db[`/quotes/${quoteId}/exclusion_approval_status`] = 'Needs Review'
        }else{
          updates_for_db[`/quotes/${quoteId}/exclusion_approval_status`] = null
        }          
      }

      function applyUpdates() {
        firebase.database().ref().update(updates_for_db)
        .then(() => self.$emit('close'))
        .catch(error => console.error("Error updating quotes:", error));
      }
    }
  }
}
</script>

<style scoped>
  .v-table > .v-table__wrapper > table > tbody > tr > td{
    font-size: 10px;
  }
</style>


